<template>
    <div></div>
</template>

<script>
export default {
    components: {},
    data() {
        return {};
    },
    beforeCreate() {},
    beforeDestroy() {},
    mounted() {
        this.getBizInfo();
    },
    methods: {
        getBizInfo() {
            let userCode = this.$route.query.userCode;
            let authorizationId = this.$route.query.authorizationId;
            let appId = process.env.NODE_ENV === 'production' ? 2021003174630433 : 2021003173692931;
            let pageurl = `pages/common/patient/others-auth?authorizationId=${authorizationId}&userCode=${userCode}`;
            let aliurl = `alipays://platformapi/startapp?appId=${appId}&page=` + encodeURIComponent(pageurl);
            let newUrl = `https://ds.alipay.com/?scheme=` + encodeURIComponent(aliurl);
            console.log('aliurl:', aliurl, 'newUrl:', newUrl);
            window.location.href = newUrl;
        }
    }
};
</script>

<style lang="scss" scoped></style>
